import React from 'react'
import { Link } from 'gatsby'
import footerdata from '../../data/footerdata.json'
import Logo from './Logo'
import getIcon from '../../utils/getIcon'
import Subscribe from './subscribe'

const Footer = () => {
  return (
    <footer className='footer-area'>
      <div className='container'>
        <div className='footer-top'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='footer-logo'>
              <Logo />
            </div>
            <div className='footer-social text-sm-right'>
              <span>FOLLOW US</span>
              <ul className='social-icon'>
                {footerdata.socialicon.map((item, i) => {
                  let Icon = getIcon(item.icon)
                  return (
                    <li key={i}>
                      <a href={item.url} target='_blank'>
                        <Icon />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='row'>
            <div className='col-lg-3 col-sm-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{footerdata.aboutus.title}</h4>
                <p className='text-muted'>{footerdata.aboutus.subText}</p>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>
                  {footerdata.popularsearch.title}
                </h4>
                <ul>
                  {footerdata.popularsearch.links.map((item, i) => (
                    <li className='readeal-top' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <div className='widget widget_nav_menu mb-4'>
                <h4 className='widget-title'>{footerdata.companies.title}</h4>
                <ul>
                  {footerdata.companies.links.map((item, i) => (
                    <li className='readeal-top' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>{footerdata.quicklink.title}</h4>
                <ul>
                  {footerdata.quicklink.links.map((item, i) => (
                    <li className='readeal-top' key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-sm-6'>
              <h4>Subscribe to Our Newsletter</h4>
              <Subscribe/>
            </div>
          </div>
        </div>
        <div
          className='copy-right text-center'
          dangerouslySetInnerHTML={{ __html: footerdata.copyrighttext }}
        ></div>
      </div>
    </footer>
  )
}

export default Footer
