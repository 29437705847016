import React, { useLayoutEffect } from 'react'
import { Link } from 'gatsby'
import { NavDropdown } from 'react-bootstrap'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import Logo from './Logo'

function renderNavItems(items) {
  return (
    <ul className='navbar-nav menu-open readeal-top'>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item }), [])}
    </ul>
  )
}

function reduceChildRoutes({ acc, item }) {
  const key = item.title
  if (item.items) {
    acc.push(
      <li className='menu-item-has-children' key={key}>
        <NavDropdown title={item.title} id='basic-nav-dropdown'>
          {item.items.map((value, index) => (
            <NavDropdown.Item as={Link} to={value.href} key={index}>
              {value.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </li>
    )
  } else {
    acc.push(
      <li key={key}>
        <Link to={item.href}>{item.title}</Link>
      </li>
    )
  }
  return acc
}

const Navbar = () => {
  const { navMenu } = useSiteMetadata()
  const [page, setPage] = React.useState('')
  const [showCollapse, setShowCollapse] = React.useState(false)

  const elementOptions = () => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setPage('navbar-area-fixed')
    } else {
      setPage('')
    }
  }

  useLayoutEffect(() => {
    window.onscroll = () => elementOptions()
  }, [])

  const handleToggle = () => {
    setShowCollapse(!showCollapse)
  }

  return (
    <div>
      <div className='navbar-area'>
        <nav className={'navbar navbar-area navbar-expand-lg ' + page}>
          <div className='container nav-container'>
            <div className='responsive-mobile-menu'>
              <button
                onClick={handleToggle}
                className={`menu toggle-btn d-block d-lg-none ${
                  showCollapse && 'open collapsed'
                }`}
                data-toggle='collapse'
                data-target='#realdeal_main_menu'
                aria-expanded={showCollapse}
                aria-label='Toggle navigation'
              >
                <span className='icon-left' />
                <span className='icon-right' />
              </button>
            </div>
            <div className='logo readeal-top'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>
            <div className='nav-right-part nav-right-part-mobile'>
              <Link className='btn btn-yellow' to='/contact'>
                CONTACT US
              </Link>
            </div>
            <div
              className={`collapse navbar-collapse ${showCollapse && 'show'}`}
              id='realdeal_main_menu'
            >
              {renderNavItems(navMenu)}
            </div>
            <div className='nav-right-part nav-right-part-desktop readeal-top'>
              <Link className='btn btn-yellow' to='/contact'>
                CONTACT US
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
