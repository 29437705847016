import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';


export default class Subscribe extends React.Component {

    state = {
        email: '',
        message: '',
        fname: '',
        phone: ''
    };
    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };
    
    handleSubmit = async (e) => {
        e.preventDefault();
        
        console.log(this.state.email)
        
        const result = await addToMailchimp(this.state.email, this.state);
        console.log(result)
        this.setState({ message: result.msg, email: "" });
    };

    
    
    render()  
    {
       
        return (
            <div >
            <form
            className='widget widget-subscribe'
            name="subscribeForm"
            method="POST" 
            id="subscribe-form" 
           
            onSubmit={this.handleSubmit}>
                <div className="message" dangerouslySetInnerHTML= {{ __html: this.state.message}} />

                <div className='rld-single-input'>
                <input className=""
                         type="text" 
                         name="fname" 
                         placeholder="Full Name"
                        value={this.state.fname}
                        onChange={this.handleInputChange} />
                </div>
                
                         
                    <div className='rld-single-input'>
                    <input className=""
                         type="tel" 
                         name="phone" 
                         placeholder="Phone Number"
                        value={this.state.phone}
                        onChange={this.handleInputChange} />
                    </div>
                         
                    
                    
                <div className='rld-single-input'>
                <input 
                         type="email" 
                         name="email" 
                         placeholder="Enter Email Address..."
                        value={this.state.email}
                        onChange={this.handleInputChange} />
                </div>
                       
                    
                
                <input className='btn btn-yellow w-100' type="submit" value="Submit" />
                   
                
            </form>
            </div>
        );
        
    }
    
}