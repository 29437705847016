import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage as Img } from 'gatsby-plugin-image'

const logoQuery = graphql`
  query NavLogo {
    logo: file(
      absolutePath: { regex: "/logo.(jpeg|jpg|gif|png)/" }
      sourceInstanceName: { eq: "asset" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 70
          layout: FIXED
          quality: 100
          placeholder: TRACED_SVG
        )
      }
    }
  }
`

export default function Logo() {
  const { logo } = useStaticQuery(logoQuery)
  return <Img image={getImage(logo)} alt='Logo' />
}
