import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './navbar'
import Footer from './footer'
import {useRef, useEffect } from 'react'

const Layout = ({ children }) => {
  const scrolled = useRef(null)

  useEffect(() => {
      const headID = document.getElementsByTagName('head')[0]

      document.addEventListener('scroll', function (e) {
          if (!scrolled.current) {
              scrolled.current = true

              // Google Ads
              const gaScript = document.createElement('script')
              gaScript.async = true
              gaScript.crossorigin = 'anonymous'
              gaScript['data-ad-client'] = 'ca-pub-5447267023070771'
              gaScript.src =
                  'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
              gaScript.type = 'text/javascript'
              headID.appendChild(gaScript)
          }
      })
  }, [])
  return (
    <div>
      <div>
        <link
          href='https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css'
          rel='stylesheet'
        />
      </div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Layout
